/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/wa.js
*    TO   --> ./legacy-web/src/main/assets/js/common/wa.js
**/

function KeyValueObject(key, value) {
    this.key = key;
    this.value = value;
}

 function calcTotalNoOfPsgrsOnHomePage(strHotel){
 	var value;
 	if(strHotel != null && strHotel.length > 0){
 		value = jQuery('#flightSearchForm\\.adultPassengerCount\\.flightHotel').val();
 	}
 	else{
 		value = jQuery('#flightSearchForm\\.adultOrSeniorPassengerCount').val();
 	}
   	if(value == null) {
    	  value = '0';
	}
   	var numPsgrs = parseInt(value);
   	jQuery("#flightSearchForm\\.passengerCount").val(numPsgrs);
   	var numSeniors = jQuery('#flightSearchForm\\.seniorPassengerCount').val();
	jQuery('#flightSearchForm\\.adultPassengerCount').val(numPsgrs - numSeniors);
 }

function trackAllFormValues(form){
}

function trackDestinationIdeas(){
    return true;
}

function trackLogin(loginForm){
    return true;
}

function trackEvent(eventName){
	return true;
}

function addEvent(obj, evType, fn){
    if (obj.addEventListener) {
        obj.addEventListener(evType, fn, false);
        return true;
    }
    else {
        if (obj.attachEvent) {
            var r = obj.attachEvent("on" + evType, fn);
            return r;
        }
        else {
            return false;
        }
    }
}

function captureExtLink(toLink, fromLocation){
    return true;
}

function captureExtClickThru(clickThruLink, reportedLocation, reportedTitle, repository, contentId, locale, flash, reportedPosition){
    return true;
}

function trackMyAccountEvents(eventName){
    return true;
}
